import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Dayjs } from 'dayjs'
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import dayjs from '../../vendors/Dayjs'
import { customerCandidates } from '../../fixture/SearchCandidates'
import SearchTextField from './common/SearchTextField'
import Selector from './common/Selector'
import GetCustomerIdFromMetadata from '../../utils/common/GetCustomerIdFromMetadata'

type ApiCall = () => void

interface SearchInputFormProps {
  searchGroup?: string
  setSearchGroup?: React.Dispatch<React.SetStateAction<string>>
  searchText?: string
  setSearchText?: React.Dispatch<React.SetStateAction<string>>
  startDate?: Dayjs | null
  setStartDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>
  endDate?: Dayjs | null
  setEndDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>
  isResultDisplay?: boolean
  setResultDisplay?: React.Dispatch<React.SetStateAction<boolean>>
  handleCallApi: ApiCall
}

interface ResponsiveSearchInputFormProps {
  searchGroup: string
  setSearchGroup: React.Dispatch<React.SetStateAction<string>>
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  startDate: Dayjs | null
  endDate: Dayjs | null
  isResultDisplay: boolean
  setResultDisplay: React.Dispatch<React.SetStateAction<boolean>>
  handleCallApi: ApiCall
  handleStartChange: (newValue: Dayjs | null) => void
  handleEndChange: (newValue: Dayjs | null) => void
  handleInputClear: () => void
  candidates: Candidates[]
}

interface CustomerCandidates {
  customerId: string
  candidates: Candidates[]
}

interface Candidates {
  name: string
  value: string
}

const selectCustomerCandidatates = (
  customerCandidates: CustomerCandidates[],
  customerId: string | null
): Candidates[] => {
  const defaultCandidates: Candidates[] = [
    {
      name: '全て',
      value: 'all',
    },
  ]
  const customerCandidate = customerCandidates.find(
    (cc) => cc.customerId === customerId
  )
  if (customerCandidate != null) {
    return customerCandidate.candidates
  }
  return defaultCandidates
}

const SearchInputForm: React.FC<SearchInputFormProps> = (
  props: SearchInputFormProps
) => {
  const { user } = useAuth0()
  const customerId = GetCustomerIdFromMetadata(user)
  const candidates = selectCustomerCandidatates(customerCandidates, customerId)
  const {
    searchGroup,
    setSearchGroup,
    searchText,
    setSearchText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isResultDisplay,
    setResultDisplay,
    handleCallApi,
  } = props

  if (
    searchGroup === undefined ||
    setSearchGroup === undefined ||
    searchText === undefined ||
    setSearchText === undefined ||
    startDate === undefined ||
    setStartDate === undefined ||
    endDate === undefined ||
    setEndDate === undefined ||
    isResultDisplay === undefined ||
    setResultDisplay === undefined
  ) {
    return <></>
  }

  const handleStartChange = (newValue: Dayjs | null): void => {
    setStartDate(newValue)
  }
  const handleEndChange = (newValue: Dayjs | null): void => {
    setEndDate(newValue)
  }

  const handleInputClear = (): void => {
    const nowDays = dayjs()
    setSearchGroup('')
    setSearchText('')
    setStartDate(nowDays.startOf('month'))
    setEndDate(nowDays.add(2, 'month').endOf('month'))
    setResultDisplay(false)
  }

  return (
    <>
      <SmSearchInputForm
        searchGroup={searchGroup}
        setSearchGroup={setSearchGroup}
        searchText={searchText}
        setSearchText={setSearchText}
        startDate={startDate}
        endDate={endDate}
        isResultDisplay={isResultDisplay}
        setResultDisplay={setResultDisplay}
        handleCallApi={handleCallApi}
        handleStartChange={handleStartChange}
        handleEndChange={handleEndChange}
        handleInputClear={handleInputClear}
        candidates={candidates}
      />
      <MdSearchInputForm
        searchGroup={searchGroup}
        setSearchGroup={setSearchGroup}
        searchText={searchText}
        setSearchText={setSearchText}
        startDate={startDate}
        endDate={endDate}
        isResultDisplay={isResultDisplay}
        setResultDisplay={setResultDisplay}
        handleCallApi={handleCallApi}
        handleStartChange={handleStartChange}
        handleEndChange={handleEndChange}
        handleInputClear={handleInputClear}
        candidates={candidates}
      />
    </>
  )
}

const SmSearchInputForm: React.FC<ResponsiveSearchInputFormProps> = (
  props: ResponsiveSearchInputFormProps
) => {
  const {
    searchGroup,
    setSearchGroup,
    searchText,
    setSearchText,
    startDate,
    endDate,
    isResultDisplay,
    setResultDisplay,
    handleCallApi,
    handleStartChange,
    handleEndChange,
    handleInputClear,
    candidates,
  } = props

  return (
    <>
      <div className="md:hidden m-10 px-7 py-2 bg-white">
        <Typography className="px-3 py-2" variant="h6">
          検索条件
        </Typography>
        <div className="mt-1">
          <Selector
            candidates={candidates}
            selectValue={searchGroup}
            setSelectValue={setSearchGroup}
            label={'種別'}
          />
        </div>
        <div className="w-3/5 py-2 my-2 pr-2">
          <SearchTextField
            textValue={searchText}
            setTextValue={setSearchText}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-center',
            }}
          >
            <CalendarMonthOutlinedIcon sx={{ mr: 1, my: 2 }} />
            <MobileDatePicker
              label="start"
              inputFormat="YYYY/MM/DD"
              value={startDate}
              onChange={handleStartChange}
              renderInput={(params) => <TextField {...params} />}
              closeOnSelect={true}
            />
            <Box className="mx-4 my-4 text-xl">~</Box>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'flex-center',
            }}
          >
            <CalendarMonthOutlinedIcon sx={{ mr: 1, my: 2 }} />
            <MobileDatePicker
              label="end"
              inputFormat="YYYY/MM/DD"
              value={endDate}
              onChange={handleEndChange}
              renderInput={(params) => <TextField {...params} />}
              closeOnSelect={true}
            />
          </Box>
          <FormControlLabel
            sx={{
              display: 'block',
              my: 1,
            }}
            control={
              <Switch
                checked={isResultDisplay}
                onChange={() => setResultDisplay(!isResultDisplay)}
                name=""
              />
            }
            label="実績表示"
          />
          <Box sx={{ ml: 0, mt: 1 }}>
            <Button variant="outlined" onClick={handleInputClear}>
              入力クリア
            </Button>
            <Button sx={{ ml: 2 }} variant="contained" onClick={handleCallApi}>
              検索
            </Button>
          </Box>
        </LocalizationProvider>
      </div>
    </>
  )
}

const MdSearchInputForm: React.FC<ResponsiveSearchInputFormProps> = (
  props: ResponsiveSearchInputFormProps
) => {
  const {
    searchGroup,
    setSearchGroup,
    searchText,
    setSearchText,
    startDate,
    endDate,
    isResultDisplay,
    setResultDisplay,
    handleCallApi,
    handleStartChange,
    handleEndChange,
    handleInputClear,
    candidates,
  } = props

  return (
    <>
      <div className="md:block hidden m-10 px-5 py-2 bg-white">
        <Typography className="px-3 py-2" variant="h6">
          検索条件
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginLeft: 6,
          }}
        >
          <div className="mt-1">
            <Selector
              candidates={candidates}
              selectValue={searchGroup}
              setSelectValue={setSearchGroup}
              label={'種別'}
            />
          </div>
          <div className="w-2/5 ml-16 py-2 pr-2">
            <SearchTextField
              textValue={searchText}
              setTextValue={setSearchText}
            />
          </div>
        </Box>
        <Box
          className="m-5"
          sx={{
            display: 'flex',
            alignItems: 'flex-center',
            justifyContent: 'space-between',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-center',
              }}
            >
              <CalendarMonthOutlinedIcon sx={{ mr: 1, my: 2 }} />
              <MobileDatePicker
                label="start"
                inputFormat="YYYY/MM/DD"
                value={startDate}
                onChange={handleStartChange}
                renderInput={(params) => <TextField {...params} />}
                closeOnSelect={true}
              />
              <Box className="mx-4 my-4 text-xl">~</Box>
              <CalendarMonthOutlinedIcon sx={{ mr: 1, my: 2 }} />
              <MobileDatePicker
                label="end"
                inputFormat="YYYY/MM/DD"
                value={endDate}
                onChange={handleEndChange}
                renderInput={(params) => <TextField {...params} />}
                closeOnSelect={true}
              />
              <FormControlLabel
                sx={{
                  display: 'block',
                  ml: 4,
                  my: 1,
                }}
                control={
                  <Switch
                    checked={isResultDisplay}
                    onChange={() => setResultDisplay(!isResultDisplay)}
                    name=""
                  />
                }
                label="実績表示"
              />
            </Box>
            <Box sx={{ ml: 8, mt: 1 }}>
              <Button variant="outlined" onClick={handleInputClear}>
                入力クリア
              </Button>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={handleCallApi}
              >
                検索
              </Button>
            </Box>
          </LocalizationProvider>
        </Box>
      </div>
    </>
  )
}

export default SearchInputForm
