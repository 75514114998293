import {
  MpSeriesType,
  MpApiResponseTotal,
} from '../../models/MonthlyProductPlan'

/*
歯抜けのmonthは
{
  month: 1,
  production_output: 0
}
として補完してレスポンスする
*/

interface ColorOrder {
  order: number
  color: string
}

interface ApiResponseParserProps {
  total: MpApiResponseTotal[] | undefined
  monthOrderNumbers: number[]
  colorOrder: ColorOrder[]
}

export const apiResponseParser = (
  props: ApiResponseParserProps
): MpSeriesType[] => {
  const { total, colorOrder } = props
  if (total == null) {
    return []
  }

  const orderLength = colorOrder.length
  let targetOrder = 1
  return total.map((before) => {
    const data = props.monthOrderNumbers.map((i) => {
      const found = before.monthly.find((ele) => ele.month === i)
      if (found != null) {
        return found.production_output
      } else {
        return 0
      }
    })

    const colorFound = props.colorOrder.find((co) => co.order === targetOrder)
    targetOrder += 1
    if (targetOrder > orderLength) {
      targetOrder = 1
    }
    let color = ''
    if (colorFound != null) {
      color = colorFound.color
    }
    return {
      name: before.product_id,
      group_name: before.product_group_name,
      data,
      color,
    }
  })
}

export const sumMonthlyProduct = (series: MpSeriesType[]): number[] => {
  return series.reduce(function (sumArray, element) {
    return sumArray.map((sum, sumi) => sum + element.data[sumi])
  }, new Array<number>(12).fill(0))
}

interface ProductGroupFilterProps {
  productGroupValue: string | undefined
  products: MpSeriesType[] | undefined
}

export const productGroupFilter = (
  props: ProductGroupFilterProps
): MpSeriesType[] => {
  const { productGroupValue, products } = props
  if (products == null) {
    return []
  }
  if (productGroupValue === 'all' || productGroupValue == null) {
    return products
  } else {
    return products.filter((pd) => pd.group_name === productGroupValue)
  }
}
