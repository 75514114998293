import React from 'react'
import { Select, MenuItem, SelectChangeEvent, FormControl } from '@mui/material'
import { MpApiResponseTotal } from '../../models/MonthlyProductPlan'

interface ProductionGroupSelectorProps {
  products: MpApiResponseTotal[] | undefined
  productGroupValue?: string
  setProductGroupValue?: React.Dispatch<React.SetStateAction<string>>
}

const ProductionGroupSelector: React.FC<ProductionGroupSelectorProps> = (
  props: ProductionGroupSelectorProps
) => {
  const { products, productGroupValue, setProductGroupValue } = props

  const groups = React.useMemo(() => {
    if (products == null) {
      return []
    } else {
      return Array.from(new Set(products.map((pd) => pd.product_group_name)))
    }
  }, [products])

  React.useEffect(() => {
    if (groups.length > 0 && setProductGroupValue != null) {
      const firstGroupValue = groups[0]
      setProductGroupValue(firstGroupValue)
    }
  }, [groups, setProductGroupValue])

  if (productGroupValue == null || setProductGroupValue == null) {
    return <></>
  }
  const handleGroupSelect = (event: SelectChangeEvent): void => {
    const selectedGroupValue = event.target.value
    setProductGroupValue(selectedGroupValue)
  }

  return (
    <>
      <FormControl sx={{ minWidth: 200 }}>
        <Select
          id="select-group-id"
          value={productGroupValue}
          onChange={handleGroupSelect}
        >
          {groups.map((gr, gri) => (
            <MenuItem value={gr} key={`addKey-${gri}`}>
              {gr}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default ProductionGroupSelector
