import React from 'react'
import { Dayjs } from 'dayjs'
import { MpApiResponseTotal } from '../models/MonthlyProductPlan'
import dayjs from '../vendors/Dayjs'

interface DefaultContext {
  mpYearValue?: Dayjs | null
  mpSetYearValue?: React.Dispatch<React.SetStateAction<Dayjs | null>>
  mpApiResponseTotal?: MpApiResponseTotal[]
  mpSetApiResponseTotal?: React.Dispatch<
    React.SetStateAction<MpApiResponseTotal[]>
  >
  productGroupValue?: string
  setProductGroupValue?: React.Dispatch<React.SetStateAction<string>>
}

interface MonthlyProviderProps {
  children: React.ReactNode
}

const getInitFiscalYear = (
  nowDayjs: Dayjs,
  nowMonth: number,
  startFiscalMonth: number
): Dayjs => {
  let initFiscalYear = nowDayjs
  if (nowMonth < startFiscalMonth) {
    initFiscalYear = initFiscalYear.subtract(1, 'year')
  }
  return initFiscalYear
}

const MonthlyContext = React.createContext<DefaultContext>({})
const nowDayjs = dayjs()
const nowMonth = nowDayjs.month() + 1
const startFiscalMonth = 4
const initFiscalYear = getInitFiscalYear(nowDayjs, nowMonth, startFiscalMonth)

const MonthlyProvider: React.FC<MonthlyProviderProps> = ({ children }) => {
  const [mpYearValue, mpSetYearValue] = React.useState<Dayjs | null>(
    getInitFiscalYear(nowDayjs, nowMonth, startFiscalMonth)
  )
  const [mpApiResponseTotal, mpSetApiResponseTotal] = React.useState(
    [] as MpApiResponseTotal[]
  )
  const [productGroupValue, setProductGroupValue] = React.useState<string>('')

  return (
    <MonthlyContext.Provider
      value={{
        mpYearValue,
        mpSetYearValue,
        mpApiResponseTotal,
        mpSetApiResponseTotal,
        productGroupValue,
        setProductGroupValue,
      }}
    >
      {children}
    </MonthlyContext.Provider>
  )
}

export { MonthlyProvider, MonthlyContext, initFiscalYear }
