import { Routes, Route } from 'react-router-dom'
import Home from './views/pages/home/Home'
import MonthlyProductPlan from './views/pages/monthly_product_plan/MonthlyProductPlan'
import PlanAndAchievement from './views/pages/plan_and_achievement/PlanAndAchievement'
import DeliveryDateReplyInput from './views/pages/delivery_date_reply_input/DeliveryDateReplyInput'
import NotFound from './views/pages/not_found/NotFound'
import React from 'react'

interface RouteInfo {
  path: string
  element: React.FC
}

interface RouteComponentProps extends RouteInfo {
  index: number
}

const routesInfo: RouteInfo[] = [
  {
    path: '/',
    element: Home,
  },
  {
    path: '/monthly_product_plan/',
    element: MonthlyProductPlan,
  },
  {
    path: '/plan_and_achievement/',
    element: PlanAndAchievement,
  },
  {
    path: '/delivery_date_reply_input/',
    element: DeliveryDateReplyInput,
  },
  {
    path: '*',
    element: NotFound,
  },
]

const RouteComponent: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <Route path={props.path} element={<props.element />} key={props.index} />
  )
}

const Router: React.FC = () => {
  return (
    <>
      <Routes>
        {routesInfo.map((route, index) =>
          RouteComponent({ path: route.path, element: route.element, index })
        )}
      </Routes>
    </>
  )
}

export default Router
