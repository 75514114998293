import React from 'react'
import { Navigate } from 'react-router-dom'
import { PermissionContext } from '../../../contexts'

const Home: React.FC = () => {
  /*
  inner && outer => /plan_and_achievement
  inner => /plan_and_achievement
  outer => /delivery_date_reply_input
  no auth => 遷移なし
  */
  const { permissions } = React.useContext(PermissionContext)

  if (permissions === undefined) {
    return <></>
  }

  const haveInner = permissions?.includes('auth:inner')
  const haveOuter = permissions?.includes('auth:outer')

  if (haveInner) {
    return <Navigate to="/plan_and_achievement" />
  } else if (haveOuter) {
    return <Navigate to="/delivery_date_reply_input" />
  } else {
    return <></>
  }
}

export default Home
