import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { MonthlyContext, PermissionContext } from '../../contexts'
import { monthlyProductPlanApiRequester } from '../../vendors/axios/ApiRequester'
// import {
//   paApiMock,
//   mpApiMock,
//   mpManyApiMock,
// } from '../mock/ApiMocks'

const AUTH0_AUTHORIZER_IDENTIFIER = process.env
  .REACT_APP_AUTH0_AUTHORIZER_IDENTIFIER as string
const APIGATEWAY_BASEURL = process.env.REACT_APP_APIGATEWAY_BASEURL as string

const useInitMonthlyState = (): void => {
  const { getAccessTokenSilently } = useAuth0()
  const { mpYearValue, mpSetApiResponseTotal } =
    React.useContext(MonthlyContext)
  const { permissions } = React.useContext(PermissionContext)

  React.useEffect(() => {
    if (
      mpYearValue == null ||
      mpSetApiResponseTotal == null ||
      permissions == null ||
      !permissions.includes('auth:inner')
    ) {
      return
    }
    const yearValue = mpYearValue?.year()
    ;(async () => {
      const accessToken = await getAccessTokenSilently({
        audience: AUTH0_AUTHORIZER_IDENTIFIER,
        scope: 'auth:inner',
      })
      await monthlyProductPlanApiRequester({
        path: {
          year: String(yearValue),
        },
        apiGatewayBaseUrl: APIGATEWAY_BASEURL,
        accessToken,
        mpSetApiResponseTotal,
      })
    })().catch((err) => {
      console.log('getAccessToken failed', err)
      mpSetApiResponseTotal([])
    })
  }, [mpYearValue, mpSetApiResponseTotal, getAccessTokenSilently, permissions])

  // mock
  // React.useEffect(() => {
  //   if (mpYearValue == null) {
  //     return
  //   }
  //   console.log(mpYearValue?.year())
  //   if (mpYearValue?.year() === 2023) {
  //     mpSetApiResponseTotal(mpManyApiMock.total)
  //   } else {
  //     mpSetApiResponseTotal(mpApiMock.total)
  //   }
  // }, [mpYearValue, getAccessTokenSilently])
}

export default useInitMonthlyState
