import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AccessTokenDecoder } from './AccessTokenDecoder'
import { PermissionContext } from '../../contexts'

const stage = process.env.REACT_APP_ENV_STAGE
const AUTHO_AUTHORIZER_IDENTIFIER = process.env
  .REACT_APP_AUTH0_AUTHORIZER_IDENTIFIER as string

const usePermissions = (): void => {
  const { getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0()
  const { setPermissions } = React.useContext(PermissionContext)
  React.useEffect(() => {
    ;(async () => {
      try {
        let accessToken
        if (stage === 'local') {
          accessToken = await getAccessTokenWithPopup({
            audience: AUTHO_AUTHORIZER_IDENTIFIER,
            scope: 'auth:inner auth:outer',
          })
        } else {
          accessToken = await getAccessTokenSilently({
            audience: AUTHO_AUTHORIZER_IDENTIFIER,
            scope: 'auth:inner auth:outer',
          })
        }
        const tokenDecoder = new AccessTokenDecoder(accessToken)
        const decodedPermissions = tokenDecoder.getPermissions()
        if (setPermissions !== undefined) {
          setPermissions(decodedPermissions)
        }
      } catch (err) {
        console.log('getAccessToken failed', err)
      }
    })().catch((err) => {
      console.log(err)
    })
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setPermissions])
}

export default usePermissions
