import jwt_decode from 'jwt-decode'
import { Permission } from '../../models/Permission'

export class AccessTokenDecoder {
  readonly decoded: {
    [key: string]: string
  }

  constructor(accessToken: string) {
    this.decoded = jwt_decode(accessToken)
  }

  public getPermissions(): Permission[] {
    if (Array.isArray(this.decoded.permissions)) {
      return this.decoded.permissions
    } else {
      return []
    }
  }
}
