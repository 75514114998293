import React from 'react'
import { Box, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

interface SearchTextFieldProps {
  textValue?: string
  setTextValue?: React.Dispatch<React.SetStateAction<string>>
}

const SearchTextField: React.FC<SearchTextFieldProps> = (
  props: SearchTextFieldProps
) => {
  const { textValue, setTextValue } = props
  if (textValue == null || setTextValue == null) {
    return <></>
  }

  return (
    <>
      <Box
        className="bg-gray-100 rounded"
        sx={{ display: 'flex', alignItems: 'flex-end' }}
      >
        <SearchIcon sx={{ color: 'ActiveCaption.active', mx: 1, my: 1.2 }} />
        <TextField
          className="w-full"
          id="search-input"
          label="検索キーワード"
          variant="standard"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
        />
      </Box>
    </>
  )
}

export default SearchTextField
