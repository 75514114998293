import React from 'react'
import { Box, Alert, AlertColor } from '@mui/material'

interface InformAlertProps {
  openAlert: boolean
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>
  alertInfo: AlertInfo
}

interface AlertInfo {
  severity: AlertColor | undefined
  message: string
}

const InformAlert: React.FC<InformAlertProps> = (props: InformAlertProps) => {
  const { openAlert, setOpenAlert, alertInfo } = props

  const onClose = (): void => {
    setOpenAlert(false)
  }

  return openAlert ? (
    <Box my={2} mb={2}>
      <Alert severity={alertInfo.severity} onClose={onClose}>
        {alertInfo.message}
      </Alert>
    </Box>
  ) : (
    <></>
  )
}
export default InformAlert
