import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { DeliveryContext, PermissionContext } from '../../contexts'
import { deliveryDateReplyInputApiRequester } from '../../vendors/axios/ApiRequester'
// import { deliveryApiResponseMock } from '../../mock/DeliveryMocks'

const AUTH0_AUTHORIZER_IDENTIFIER = process.env
  .REACT_APP_AUTH0_AUTHORIZER_IDENTIFIER as string
const APIGATEWAY_BASEURL = process.env.REACT_APP_APIGATEWAY_BASEURL as string

const useInitDeliveryState = (): void => {
  const { getAccessTokenSilently } = useAuth0()
  const { setProcurementApiResponse } = React.useContext(DeliveryContext)
  const { permissions } = React.useContext(PermissionContext)

  React.useEffect(() => {
    if (
      setProcurementApiResponse == null ||
      permissions == null ||
      !permissions.includes('auth:outer')
    ) {
      return
    }
    ;(async () => {
      const accessToken = await getAccessTokenSilently({
        audience: AUTH0_AUTHORIZER_IDENTIFIER,
        scope: 'auth:outer',
      })
      await deliveryDateReplyInputApiRequester({
        apiGatewayBaseUrl: APIGATEWAY_BASEURL,
        accessToken,
        setProcurementApiResponse,
      })
    })().catch((err) => {
      console.log('getAccessToken failed', err)
      setProcurementApiResponse({
        vendor_id: '',
        vendor_name: '',
        procurement_orders: [],
      })
    })
  }, [setProcurementApiResponse, getAccessTokenSilently, permissions])

  // mock
  // React.useEffect(() => {
  //   if (
  //     setProcurementApiResponse == null ||
  //     permissions == null ||
  //     !permissions.includes('auth:outer')
  //   ) {
  //     return
  //   }
  //   setProcurementApiResponse(deliveryApiResponseMock)
  // }, [setProcurementApiResponse, getAccessTokenSilently, permissions])
}

export default useInitDeliveryState
