import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
} from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import Menu from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import UserInfoBox from './UserInfoBox'
import { PermissionContext } from '../../contexts'
import { Permission } from '../../models/Permission'

interface LinkItemProps {
  path: string
  title: string
  LinkPermission: 'default' | Permission
  UserPermissions: Permission[] | undefined
}

interface LayoutProps {
  children: React.ReactNode
}

interface MdMenueProps {
  permissions: Permission[] | undefined
}

interface SmMenuProps {
  openSmMenu: boolean
  setOpenSmMenu: React.Dispatch<React.SetStateAction<boolean>>
  permissions: Permission[] | undefined
}

interface SmListProps {
  permissions: Permission[] | undefined
}

const LinkItem: React.FC<LinkItemProps> = (props: LinkItemProps) => {
  let permissions = ['default']
  if (props.UserPermissions !== undefined) {
    permissions = permissions.concat(props.UserPermissions)
  }
  const isActive = permissions.includes(props.LinkPermission)
  return (
    <>
      {isActive && (
        <Link to={props.path}>
          <ListItem button>
            <ListItemIcon>
              <BarChartIcon className="text-gray-200" />
            </ListItemIcon>
            <ListItemText className="font-bold" primary={props.title} />
          </ListItem>
        </Link>
      )}
    </>
  )
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const [openSmMenu, setOpenSmMenu] = React.useState(false)
  const { permissions } = React.useContext(PermissionContext)
  return (
    <>
      <div className="md:flex h-screen">
        <SmMenu
          openSmMenu={openSmMenu}
          setOpenSmMenu={setOpenSmMenu}
          permissions={permissions}
        />
        <MdMenu permissions={permissions} />
        <div
          onClick={() => setOpenSmMenu(false)}
          className="md:w-5/6 h-auto bg-gray-300 overflow-y-scroll"
        >
          <div>{children}</div>
        </div>
      </div>
    </>
  )
}

const SmMenu: React.FC<SmMenuProps> = (props: SmMenuProps) => {
  const { openSmMenu, setOpenSmMenu, permissions } = props
  return (
    <>
      <div className="md:hidden bg-gray-800 text-gray-200">
        <div className="md:hidden absolute top-1 left-0">
          {openSmMenu ? (
            <IconButton onClick={() => setOpenSmMenu(false)}>
              <CloseIcon className="text-gray-200" />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpenSmMenu(true)}>
              <Menu className="text-gray-200" />
            </IconButton>
          )}
        </div>
        <Typography className="pl-12 py-2" variant="h5">
          進捗管理システム
        </Typography>
        {openSmMenu && <SmList permissions={permissions} />}
      </div>
    </>
  )
}

const SmList: React.FC<SmListProps> = (props: SmListProps) => {
  const { permissions } = props
  const { logout, user } = useAuth0()
  const userName = user?.name != null ? user.name : ''
  return (
    <div className="absolute top-12 left-0 z-50 bg-gray-800 text-gray-200">
      <Typography className="pl-4 pt-4">計画確認メニュー</Typography>
      <List className="">
        <Divider />
        <LinkItem
          path="/monthly_product_plan/"
          title="月産計画"
          LinkPermission="auth:inner"
          UserPermissions={permissions}
        />
        <Divider />
        <LinkItem
          path="/plan_and_achievement/"
          title="生産計画・実績"
          LinkPermission="auth:inner"
          UserPermissions={permissions}
        />
        <Divider />
        <LinkItem
          path="/delivery_date_reply_input/"
          title="納期回答入力"
          LinkPermission="auth:outer"
          UserPermissions={permissions}
        />
        <Divider />
        <ListItem button className="py-4">
          <ListItemIcon
            className=""
            onClick={() => {
              logout({ returnTo: window.location.origin })
            }}
          >
            <LogoutIcon className="text-gray-200" />
          </ListItemIcon>
          <ListItemText
            className="font-bold"
            primary={`ログアウト(${userName})`}
          />
        </ListItem>
      </List>
    </div>
  )
}

const MdMenu: React.FC<MdMenueProps> = (props: MdMenueProps) => {
  const { permissions } = props
  return (
    <>
      <div className="md:block hidden static w-1/6 bg-gray-800 text-gray-200">
        <Typography className="pl-4 pt-4" variant="h5">
          進捗管理システム
        </Typography>
        <Typography className="pl-4 pt-4">計画確認メニュー</Typography>
        <List className="">
          <Divider />
          <LinkItem
            path="/monthly_product_plan/"
            title="月産計画"
            LinkPermission="auth:inner"
            UserPermissions={permissions}
          />
          <Divider />
          <LinkItem
            path="/plan_and_achievement/"
            title="生産計画・実績"
            LinkPermission="auth:inner"
            UserPermissions={permissions}
          />
          <Divider />
          <LinkItem
            path="/delivery_date_reply_input/"
            title="納期回答入力"
            LinkPermission="auth:outer"
            UserPermissions={permissions}
          />
          <Divider />
        </List>
        <div className="absolute bottom-0">
          <UserInfoBox />
        </div>
      </div>
    </>
  )
}

export default Layout
