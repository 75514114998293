import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import gantt from 'highcharts/highcharts-gantt'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Typography, LinearProgress } from '@mui/material'
import { apiResponseParser } from '../../../utils/plan_and_achievement/PlanAndAchievement'
import { SeriesData, SeriesType } from '../../../models/PlanAndAchievement'
import SearchInputForm from '../../components/SearchInputForm'
import { PlanContext } from '../../../contexts'
import dayjs from '../../../vendors/Dayjs'
import { activityNameColors } from '../../../fixture/ActivityNameColors'
// import { paApiInputMock } from '../../../mock/ApiMocks'
import { planAndAchievementApiRequester } from '../../../vendors/axios/ApiRequester'

const AUTH0_AUTHORIZER_IDENTIFIER = process.env
  .REACT_APP_AUTH0_AUTHORIZER_IDENTIFIER as string
const APIGATEWAY_BASEURL = process.env.REACT_APP_APIGATEWAY_BASEURL as string

interface SeriesProps {
  series: SeriesType[]
}

const makeGroups = (props: SeriesProps): string[] => {
  return props.series.map((i) => {
    if (i.isPlan === true) {
      return `${i.id} ${i.name}`
    } else {
      return ''
    }
  })
}

const makeRequiredQuantity = (props: SeriesProps): string[] => {
  return props.series.map((i) => {
    let stringQuantity
    if (i.requiredQuantity !== undefined && i.isPlan === true) {
      stringQuantity = `${i.requiredQuantity}`
    } else {
      stringQuantity = ''
    }
    return stringQuantity
  })
}

const makeDueDate = (props: SeriesProps): string[] => {
  return props.series.map((i) => {
    let stringDueDate
    if (i.dueDate !== undefined && i.isPlan === true) {
      stringDueDate = `${dayjs(i.dueDate).format('YYYY/MM/DD')}`
    } else {
      stringDueDate = ''
    }
    return stringDueDate
  })
}

const makeDisplay = (props: SeriesProps): string[] => {
  return props.series.map((i) => {
    if (i.isPlan === true) {
      return '計画'
    } else if (i.isPlan === false) {
      return '実績'
    } else {
      return ''
    }
  })
}

const formatSeriesData = (
  props: SeriesProps
): Highcharts.SeriesOptionsType[] => {
  return props.series.map((obj, index) => {
    obj.data = obj.data.map((datum) => {
      datum.y = index
      return addColor(datum, obj.isPlan)
    })
    return {
      name: obj.name,
      type: 'gantt',
      data: obj.data,
    }
  })
}

const addColor = (
  seriesDataObj: SeriesData,
  isPlan: boolean | undefined
): SeriesData => {
  // 実績: 仕掛中は灰色、その他は黒
  if (isPlan === false) {
    if (seriesDataObj.inProgress != null && seriesDataObj.inProgress) {
      seriesDataObj.color = '#DDDDDD'
    } else {
      seriesDataObj.color = 'black'
    }
    return seriesDataObj
  }
  // 計画: 予め指定の色 or 自動付与
  const activityInfo = activityNameColors.find((anc) =>
    seriesDataObj.name.includes(anc.id)
  )
  if (activityInfo !== undefined) {
    seriesDataObj.color = activityInfo.color
  }
  return seriesDataObj
}

const PlanAndAchievementChart: React.FC<SeriesProps> = React.memo(
  function paChartFunc(props: SeriesProps) {
    if (props.series.length > 0) {
      return (
        <>
          <div className="">
            <HighchartsReact
              constructorType={'ganttChart'}
              highcharts={gantt}
              options={chartOptions({ series: props.series })}
            />
          </div>
        </>
      )
    } else {
      return <div className="px-3 py-2 text-lg">NO DATA</div>
    }
  }
)

const chartOptions = (props: SeriesProps): Highcharts.Options => {
  return {
    responsive: {
      rules: [
        {
          condition: {
            minWidth: 768,
          },
          chartOptions: {
            xAxis: [
              {
                currentDateIndicator: {
                  label: {
                    format: '現時刻 %Y/%m/%d %H:%M',
                  },
                  color: 'teal',
                },
                dateTimeLabelFormats: {
                  day: '%e',
                  week: '%e日〜',
                  month: '%m月',
                  year: '%Y年',
                },
              },
              {
                tickInterval: 30 * 24 * 3600 * 1000,
                dateTimeLabelFormats: {
                  month: '%m月',
                },
              },
            ],
            yAxis: {
              labels: {
                style: {
                  width: 200,
                  textOverflow: 'ellipsis',
                },
              },
            },
            rangeSelector: {
              enabled: true,
            },
          },
        },
      ],
    },
    time: {
      useUTC: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
      },
    },
    series: formatSeriesData({
      series: props.series,
    }),
    xAxis: {
      currentDateIndicator: {
        label: {
          format: '現時刻 %Y/%m/%d %H:%M',
        },
        color: 'teal',
      },
      dateTimeLabelFormats: {
        day: '%e',
        week: '%e日〜',
        month: '%m月',
        year: '%Y年',
      },
    },
    yAxis: {
      staticScale: 30,
      labels: {
        style: {
          width: 30,
          textOverflow: 'ellipsis',
        },
      },
      type: 'category',
      grid: {
        columns: [
          {
            title: {
              text: 'オーダ番号 品名',
            },
            categories: makeGroups({
              series: props.series,
            }),
          },
          {
            title: {
              text: '数量',
            },
            categories: makeRequiredQuantity({
              series: props.series,
            }),
          },
          {
            title: {
              text: '納期',
            },
            categories: makeDueDate({
              series: props.series,
            }),
          },
          {
            title: {
              text: '表示',
            },
            categories: makeDisplay({
              series: props.series,
            }),
          },
        ],
      },
    },
    navigator: {
      enabled: true,
      series: {
        type: 'gantt',
        pointPadding: 0.25,
        accessibility: {
          enabled: false,
        },
      },
      xAxis: {
        dateTimeLabelFormats: {
          month: '%m月',
          year: '%Y年',
        },
      },
    },
    rangeSelector: {
      enabled: false,
      inputDateFormat: '%Y年%m月%e日',
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  }
}

const PlanAndAchievement: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [visible, setVisible] = React.useState(true)

  const {
    searchGroup,
    setSearchGroup,
    searchText,
    setSearchText,
    paStartDate,
    paSetStartDate,
    paEndDate,
    paSetEndDate,
    paIsResultDisplay,
    paSetResultDisplay,
    paApiResponse,
    paSetApiResponse,
  } = React.useContext(PlanContext)

  let series = [] as SeriesType[]
  series = React.useMemo(() => {
    if (paApiResponse == null) {
      return []
    }
    return apiResponseParser(paApiResponse)
  }, [paApiResponse])

  // MockHandler
  // const handleCallApi = (): void => {
  //   if (
  //     paSetApiResponse == null ||
  //     searchText == null ||
  //     paStartDate == null ||
  //     paEndDate == null ||
  //     paIsResultDisplay == null
  //   ) {
  //     return
  //   }

  //   if (paSetApiResponse !== undefined) {
  //     paSetApiResponse(paApiInputMock.product_orders)
  //   }
  //   // TODO Highchartsの高さが自動更新されないためページ遷移を使い強制的に更新させている。より良い改善案あれば修正したい
  //   navigation('/')
  // }

  const handleCallApi = async (): Promise<void> => {
    if (
      paSetApiResponse == null ||
      searchGroup == null ||
      searchText == null ||
      paStartDate == null ||
      paEndDate == null ||
      paIsResultDisplay == null
    ) {
      return
    }
    const dateFormat = 'YYYY-MM-DD'
    const start = paStartDate.format(dateFormat)
    const end = paEndDate.format(dateFormat)

    try {
      setVisible(false)
      const accessToken = await getAccessTokenSilently({
        audience: AUTH0_AUTHORIZER_IDENTIFIER,
        scope: 'auth:inner',
      })
      await planAndAchievementApiRequester({
        params: {
          group: searchGroup,
          text: searchText,
          start,
          end,
          actual: paIsResultDisplay,
        },
        apiGatewayBaseUrl: APIGATEWAY_BASEURL,
        accessToken,
        paSetApiResponse,
      })
      setVisible(true)
    } catch (err) {
      console.log('getAccessToken failed', err)
      paSetApiResponse([])
    }
  }

  return (
    <>
      <SearchInputForm
        searchGroup={searchGroup}
        setSearchGroup={setSearchGroup}
        searchText={searchText}
        setSearchText={setSearchText}
        startDate={paStartDate}
        setStartDate={paSetStartDate}
        endDate={paEndDate}
        setEndDate={paSetEndDate}
        isResultDisplay={paIsResultDisplay}
        setResultDisplay={paSetResultDisplay}
        handleCallApi={handleCallApi}
      />
      <div className="m-10 px-5 py-2 bg-white">
        <Typography className="px-3 py-2" variant="h6">
          計画概要
        </Typography>
        {visible ? (
          <PlanAndAchievementChart series={series} />
        ) : (
          <div className="py-4">
            <LinearProgress color="inherit" />
          </div>
        )}
      </div>
    </>
  )
}

export default PlanAndAchievement
