import React from 'react'
import { Permission } from '../models/Permission'

interface DefaultContext {
  permissions?: Permission[]
  setPermissions?: React.Dispatch<React.SetStateAction<Permission[]>>
}

interface PermissionProviderProps {
  children: React.ReactNode
}

const PermissionContext = React.createContext<DefaultContext>({})

const PermissionProvider: React.FC<PermissionProviderProps> = ({ children }) => {
  const [permissions, setPermissions] = React.useState([] as Permission[])

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        setPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export { PermissionProvider, PermissionContext }
