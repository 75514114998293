export const filters = [
  {
    name: '全て',
    value: 'all',
  },
  {
    name: '受注残',
    value: 'not_done',
  },
  {
    name: '完納',
    value: 'done',
  },
]
