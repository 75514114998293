import React from 'react'
import { Dayjs } from 'dayjs'
import { Select, MenuItem, SelectChangeEvent, FormControl } from '@mui/material'
import dayjs from '../../vendors/Dayjs'

interface YearSelectorProps {
  initFiscalYear: Dayjs
  yearValue?: Dayjs | null
  setYearValue?: React.Dispatch<React.SetStateAction<Dayjs | null>>
}

const YearSelector: React.FC<YearSelectorProps> = (
  props: YearSelectorProps
) => {
  const { initFiscalYear, yearValue, setYearValue } = props
  const yearValueString = yearValue != null ? String(yearValue.year()) : ''

  if (yearValue == null || setYearValue == null) {
    return <></>
  }
  const handleYearSelect = (event: SelectChangeEvent): void => {
    const selectedYearString = event.target.value
    setYearValue(dayjs().year(Number(selectedYearString)))
  }
  return (
    <>
      <FormControl sx={{ minWidth: 200 }}>
        <Select
          id="select-year-id"
          value={yearValueString}
          onChange={handleYearSelect}
        >
          {[1].map((subVal, subi) => (
            <MenuItem
              value={initFiscalYear.subtract(subVal, 'year').year()}
              key={`addKey-${subi}`}
            >
              {initFiscalYear.subtract(subVal, 'year').year()}年度
            </MenuItem>
          ))}
          <MenuItem value={initFiscalYear.year()}>
            {initFiscalYear.year()}年度
          </MenuItem>
          {[1, 2, 3].map((addVal, addi) => (
            <MenuItem
              value={initFiscalYear.add(addVal, 'year').year()}
              key={`addKey-${addi}`}
            >
              {initFiscalYear.add(addVal, 'year').year()}年度
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default YearSelector
