import React from 'react'
import { Dayjs } from 'dayjs'
import { PaApiResponse } from '../models/PlanAndAchievement'
import dayjs from '../vendors/Dayjs'

interface DefaultContext {
  searchGroup?: string
  setSearchGroup?: React.Dispatch<React.SetStateAction<string>>
  searchText?: string
  setSearchText?: React.Dispatch<React.SetStateAction<string>>
  paStartDate?: Dayjs | null
  paSetStartDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>
  paEndDate?: Dayjs | null
  paSetEndDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>
  paIsResultDisplay?: boolean
  paSetResultDisplay?: React.Dispatch<React.SetStateAction<boolean>>
  paApiResponse?: PaApiResponse[]
  paSetApiResponse?: React.Dispatch<React.SetStateAction<PaApiResponse[]>>
}

interface PlanProviderProps {
  children: React.ReactNode
}

const PlanContext = React.createContext<DefaultContext>({})
const nowDayjs = dayjs()
const startOfMonth = nowDayjs.startOf('month')
// const endOfAdd2Month = nowDayjs.add(2, 'month').endOf('month')
const endOfAdd2Month = nowDayjs.endOf('month')

const PlanProvider: React.FC<PlanProviderProps> = ({ children }) => {
  const [searchGroup, setSearchGroup] = React.useState('all')
  const [searchText, setSearchText] = React.useState('')
  const [paStartDate, paSetStartDate] = React.useState<Dayjs | null>(
    startOfMonth
  )
  const [paEndDate, paSetEndDate] = React.useState<Dayjs | null>(endOfAdd2Month)
  const [paIsResultDisplay, paSetResultDisplay] = React.useState(false)
  const [paApiResponse, paSetApiResponse] = React.useState(
    [] as PaApiResponse[]
  )

  return (
    <PlanContext.Provider
      value={{
        searchGroup,
        setSearchGroup,
        searchText,
        setSearchText,
        paStartDate,
        paSetStartDate,
        paEndDate,
        paSetEndDate,
        paIsResultDisplay,
        paSetResultDisplay,
        paApiResponse,
        paSetApiResponse
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}

export { PlanProvider, PlanContext }
