import dayjs from '../../vendors/Dayjs'
import { ProcurementOrder } from '../../models/DeliveryDateReplyInput'

export const procurementParserAndFileter = (props: {
  orders: ProcurementOrder[]
  deliveryFilter: string
}): ProcurementOrder[] => {
  const { orders, deliveryFilter } = props
  const dayjsParser = (date: string | undefined): string => {
    const formattedDate = dayjs(date).format('YYYY/MM/DD')
    if (formattedDate != null && formattedDate !== 'Invalid Date') {
      return formattedDate
    } else {
      return '--'
    }
  }
  const orderParser = (order: ProcurementOrder): ProcurementOrder => {
    order.desired_customer_date = dayjsParser(order.desired_customer_date)
    order.supplyer_date = dayjsParser(order.supplyer_date)
    order.shipment_date = dayjsParser(order.shipment_date)
    return order
  }
  const orderFilter = (
    order: ProcurementOrder,
    deliveryFilter: string
  ): boolean => {
    const isDone = order.is_done
    if (deliveryFilter === 'all') {
      return true
    } else if (deliveryFilter === 'not_done') {
      return isDone === 'N'
    } else if (deliveryFilter === 'done') {
      return isDone === 'Y'
    } else {
      return false
    }
  }
  return orders.flatMap((order) =>
    orderFilter(order, deliveryFilter) ? orderParser(order) : []
  )
}
