import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from '@mui/material'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { ProcurementOrder } from '../../../models/DeliveryDateReplyInput'
import { DeliveryContext } from '../../../contexts'
import DeliveryEditModal from '../../../views/components/DeliveryEditModal'
import { filters } from '../../../fixture/DeliveryFilters'
import Selector from '../../components/common/Selector'
import { procurementParserAndFileter } from '../../../utils/delivery_date_reply_input/DeliveryDateReplyInput'

interface DeliveryDateReplyHeader {
  key:
    | 'customer_name'
    | 'po_do_id'
    | 'seiban'
    | 'item_name'
    | 'qty'
    | 'desired_customer_date'
    | 'supplyer_date'
    | 'shipment_date'
  name: string
}

const DeliveryDateReplyInput: React.FC = () => {
  const initOrder = {
    customer_id: '',
    customer_name: '',
    po_do_id: '',
    seiban: '',
    item_name: '',
    qty: 0,
    desired_customer_date: '',
    supplyer_date: '',
    shipment_date: '',
    is_done: '',
  }
  const { deliveryFilter, setDeliveryFilter, procurementApiResponse } =
    React.useContext(DeliveryContext)
  const [openModal, setOpenModal] = React.useState(false)
  const [modalOrder, setModalOrder] =
    React.useState<ProcurementOrder>(initOrder)
  if (
    deliveryFilter == null ||
    setDeliveryFilter == null ||
    procurementApiResponse == null
  ) {
    return <></>
  }

  const openEditModal = (order: ProcurementOrder): void => {
    setModalOrder(order)
    setOpenModal(true)
  }
  const handleClose = (): void => setOpenModal(false)

  const series = procurementParserAndFileter({
    orders: procurementApiResponse.procurement_orders,
    deliveryFilter,
  })
  const title = procurementApiResponse.vendor_name
  const headers: DeliveryDateReplyHeader[] = [
    { key: 'customer_name', name: '発注元' },
    { key: 'seiban', name: '型式' },
    { key: 'item_name', name: '品目' },
    { key: 'qty', name: '数量' },
    { key: 'desired_customer_date', name: '計画納期' },
    { key: 'supplyer_date', name: '回答納期' },
    { key: 'shipment_date', name: '出荷' },
  ]
  const headerStyle = { fontWeight: 'bold' }
  // const displayMd = {
  //   display: 'none',
  //   '@media screen and (min-width:768px)': {
  //     display: 'block',
  //   },
  // }
  // const displaySm = {
  //   display: 'block',
  //   '@media screen and (min-width:768px)': {
  //     display: 'none',
  //   },
  // }
  return (
    <>
      <DeliveryEditModal
        open={openModal}
        handleClose={handleClose}
        order={modalOrder}
      />
      <div className="m-10">
        <TableContainer sx={{ maxHeight: 750 }} component={Paper}>
          <div className="my-2 mx-6 md:flex justify-between items-center">
            <Typography className="py-2" variant="h6">
              {title}
            </Typography>
            <div className="mt-1">
              <Selector
                candidates={filters}
                selectValue={deliveryFilter}
                setSelectValue={setDeliveryFilter}
                label={''}
              />
            </div>
          </div>
          <Table
            sx={{
              marginY: 2,
              marginX: 2,
              width: 700,
              '@media screen and (min-width:768px)': {
                width: 1025,
                marginX: 5,
              },
            }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    sx={headerStyle}
                    align="left"
                    key={`header-${header.key}`}
                  >
                    {header.name}
                  </TableCell>
                ))}
                <TableCell sx={{ ...headerStyle }} align="left">
                  編集
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {series.map((row, rowi) => (
                <TableRow key={`row-${rowi}`}>
                  {headers.map((header) => {
                    const value = row[`${header.key}`]
                    return (
                      <TableCell
                        sx={{}}
                        align="left"
                        key={`colum-${rowi}-${header.key}`}
                      >
                        {value}
                      </TableCell>
                    )
                  })}
                  <TableCell sx={{}} align="left">
                    <IconButton onClick={() => openEditModal(row)}>
                      <ModeEditOutlineOutlinedIcon sx={{}} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default DeliveryDateReplyInput
