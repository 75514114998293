import { useAuth0 } from '@auth0/auth0-react'
import Router from './router'
import { Button, Typography, Box } from '@mui/material'
import Menu from './views/components/Menu'
import usePermissions from './utils/common/usePermissions'
import useInitMonthlyState from './utils/monthly_product_plan/useInitMonthlyState'
import useInitDeliveryState from './utils/delivery_date_reply_input/useInitDeliveryState'

const App: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  return (
    <>
      {isAuthenticated ? (
        <>
          <AuthenticatedPage />
        </>
      ) : (
        <>
          <UnAuthenticatedPage />
        </>
      )}
    </>
  )
}

const UnAuthenticatedPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <>
      <Box className="m-4">
        <Typography className="pb-2 " variant="h5">
          Takumi
        </Typography>
        <Button variant="contained" onClick={loginWithRedirect}>
          Log in
        </Button>
      </Box>
    </>
  )
}

const AuthenticatedPage: React.FC = () => {
  usePermissions()
  useInitMonthlyState()
  useInitDeliveryState()
  return (
    <>
      <Menu>
        <Router />
      </Menu>
    </>
  )
}

export default App
