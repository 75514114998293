export const monthOrder = [
  {
    index: 1,
    month: 4,
    display: '4月',
  },
  {
    index: 2,
    month: 5,
    display: '5月',
  },
  {
    index: 3,
    month: 6,
    display: '6月',
  },
  {
    index: 4,
    month: 7,
    display: '7月',
  },
  {
    index: 5,
    month: 8,
    display: '8月',
  },
  {
    index: 6,
    month: 9,
    display: '9月',
  },
  {
    index: 7,
    month: 10,
    display: '10月',
  },
  {
    index: 8,
    month: 11,
    display: '11月',
  },
  {
    index: 9,
    month: 12,
    display: '12月',
  },
  {
    index: 10,
    month: 1,
    display: '1月',
  },
  {
    index: 11,
    month: 2,
    display: '2月',
  },
  {
    index: 12,
    month: 3,
    display: '3月',
  },
]
