import { Box } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import LogoutIcon from '@mui/icons-material/Logout'

const UserInfoBox: React.FC = () => {
  const { logout, user } = useAuth0()
  return (
    <>
      <Box className="pl-4 py-4 text-gray-200 w-9/12">{user?.name}</Box>
      <div className="pl-4 pb-10">
        <button
          className="text-gray-200 border-solid border rounded-full w-30 p-1 hover:bg-gray-700"
          onClick={() => {
            logout({ returnTo: window.location.origin })
          }}
        >
          <div className="flex mx-4">
            <LogoutIcon />
            <Box className="ml-1 text-sm leading-relaxed">ログアウト</Box>
          </div>
        </button>
      </div>
    </>
  )
}

export default UserInfoBox
