import { User } from '@auth0/auth0-react'

interface AppMeatadata {
  customer_id: string
  customer_name: string
  vendor_id: string
}

const getCustomerIdFromMetadata = (user: User | undefined): string | null => {
  if (
    user == null ||
    user['https://cloudtakumi.com/claims/appMetadata'] == null
  ) {
    return null
  }
  const appMetadata = user[
    'https://cloudtakumi.com/claims/appMetadata'
  ] as string
  const parsedMetada = JSON.parse(appMetadata) as AppMeatadata
  if (parsedMetada == null || parsedMetada.customer_id == null) {
    return null
  }
  return parsedMetada.customer_id
}

export default getCustomerIdFromMetadata
