import {
  SeriesType,
  SeriesData,
  PaApiResponse,
} from '../../models/PlanAndAchievement'

export const apiResponseParser = (
  apiResponse: PaApiResponse[]
): SeriesType[] => {
  return apiResponse.map((before) => {
    const data: SeriesData[] = before.tasks.map((task) => {
      const newTask = {
        name: task.activity_name,
        start: Date.parse(task.start_date),
        end: Date.parse(task.end_date),
        inProgress: task.in_progress,
      }
      if (task.in_progress != null && task.in_progress) {
        newTask.inProgress = true
      } else {
        newTask.inProgress = false
      }
      return newTask
    })
    if (before.is_plan) {
      data.push({
        name: '納期',
        start: Date.parse(before.essence_due_date),
        milestone: true,
      })
    }
    return {
      id: before.product_order_id,
      name: before.item_name,
      isPlan: before.is_plan,
      dueDate: Date.parse(before.due_date),
      requiredQuantity: before.required_quantity,
      data,
    }
  })
}
