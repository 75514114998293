import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import {
  MonthlyProvider,
  PlanProvider,
  PermissionProvider,
  DeliveryProvider,
} from './contexts'

const AUTH0_AUTHORIZER_IDENTIFIER = process.env
  .REACT_APP_AUTH0_AUTHORIZER_IDENTIFIER as string
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={AUTH0_AUTHORIZER_IDENTIFIER}
    scope="auth:inner auth:outer"
  >
    <BrowserRouter>
      <PermissionProvider>
        <PlanProvider>
          <MonthlyProvider>
            <DeliveryProvider>
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </DeliveryProvider>
          </MonthlyProvider>
        </PlanProvider>
      </PermissionProvider>
    </BrowserRouter>
  </Auth0Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
