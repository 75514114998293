import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Dayjs } from 'dayjs'
import dayjs from '../../vendors/Dayjs'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Box,
  Button,
  TextField,
  CircularProgress,
  AlertColor,
} from '@mui/material'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { ProcurementOrder } from '../../models/DeliveryDateReplyInput'
import {
  deliveryDateReplyInputWriteApiRequester,
  deliveryDateReplyInputApiRequester,
} from '../../vendors/axios/ApiRequester'
import InformAlert from './common/InformAlert'
import { DeliveryContext, PermissionContext } from '../../contexts'

const AUTH0_AUTHORIZER_IDENTIFIER = process.env
  .REACT_APP_AUTH0_AUTHORIZER_IDENTIFIER as string
const APIGATEWAY_BASEURL = process.env.REACT_APP_APIGATEWAY_BASEURL as string

interface DeliveryEditModalProps {
  open: boolean
  handleClose: () => void
  order: ProcurementOrder
}

interface DeliveryDateReplyHeader {
  key: 'seiban' | 'item_name' | 'qty'
  name: string
}

interface AlertInfo {
  severity: AlertColor | undefined
  message: string
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'w-auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const DeliveryEditModal: React.FC<DeliveryEditModalProps> = (
  props: DeliveryEditModalProps
) => {
  const procurementOrderSupplyerDate = props.order.supplyer_date
  const [inputDueDate, setInputDueDate] = React.useState<Dayjs | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)
  const [alertInfo, setAlertInfo] = React.useState<AlertInfo>({
    severity: undefined,
    message: '',
  })
  const { getAccessTokenSilently } = useAuth0()
  const { setProcurementApiResponse } = React.useContext(DeliveryContext)
  const { permissions } = React.useContext(PermissionContext)

  const handleDueDateChange = (newValue: Dayjs | null): void => {
    setInputDueDate(newValue)
  }

  const { open, handleClose, order } = props
  React.useEffect(() => {
    const initDayjsValue = dayjs(procurementOrderSupplyerDate)
    const initInput = (): void => {
      setInputDueDate(initDayjsValue)
      setOpenAlert(false)
    }
    // modalを開いた時に入力を初期化
    if (open) {
      initInput()
    }
  }, [open, procurementOrderSupplyerDate])
  const title = '納期回答登録'
  const headers: DeliveryDateReplyHeader[] = [
    { key: 'seiban', name: '型式' },
    { key: 'item_name', name: '品目' },
    { key: 'qty', name: '数量' },
  ]
  const handlePostApi = async (poDoId: string): Promise<void> => {
    if (inputDueDate == null) {
      // 未入力時のリクエストを防ぐ
      setAlertInfo({
        severity: 'error',
        message: '回答納期, 出荷日が未入力です',
      })
      setOpenAlert(true)
      return
    }
    const dateFormat = 'YYYY-MM-DD'
    try {
      setLoading(true)
      const accessToken = await getAccessTokenSilently({
        audience: AUTH0_AUTHORIZER_IDENTIFIER,
        scope: 'auth:inner',
      })
      await deliveryDateReplyInputWriteApiRequester({
        data: {
          supplyer_date: inputDueDate.format(dateFormat),
        },
        path: {
          poDoId,
        },
        apiGatewayBaseUrl: APIGATEWAY_BASEURL,
        accessToken,
      })
      await updateProcurement()
      handleClose()
    } catch (err) {
      setAlertInfo({
        severity: 'error',
        message: '登録に失敗しました.',
      })
      setOpenAlert(true)
      console.log('getAccessToken failed', err)
    } finally {
      setLoading(false)
    }
  }
  const updateProcurement = async (): Promise<void> => {
    if (
      setProcurementApiResponse == null ||
      permissions == null ||
      !permissions.includes('auth:outer')
    ) {
      return
    }
    try {
      const accessToken = await getAccessTokenSilently({
        audience: AUTH0_AUTHORIZER_IDENTIFIER,
        scope: 'auth:outer',
      })
      await deliveryDateReplyInputApiRequester({
        apiGatewayBaseUrl: APIGATEWAY_BASEURL,
        accessToken,
        setProcurementApiResponse,
      })
    } catch (err) {
      console.log('getAccessToken failed', err)
      setProcurementApiResponse({
        vendor_id: '',
        vendor_name: '',
        procurement_orders: [],
      })
    }
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography className="px-6 pt-4" variant="h6">
            {title}
          </Typography>
          <Table
            sx={{ marginY: 2, marginX: 5, width: 400 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    align="left"
                    key={`header-${header.key}`}
                  >
                    {header.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {headers.map((header) => {
                  const value = order[`${header.key}`]
                  return (
                    <TableCell sx={{}} align="left" key={`colum-${header.key}`}>
                      {value}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableBody>
          </Table>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-center',
                mx: 5,
                my: 3,
              }}
            >
              <CalendarMonthOutlinedIcon sx={{ mr: 1, my: 2 }} />
              <MobileDatePicker
                label="回答納期"
                inputFormat="YYYY/MM/DD"
                value={inputDueDate}
                onChange={handleDueDateChange}
                renderInput={(params) => <TextField {...params} />}
                closeOnSelect={true}
              />
              {loading && (
                <div className="pl-8 pt-2">
                  <CircularProgress color="inherit" />
                </div>
              )}
            </Box>
          </LocalizationProvider>
          <Box
            sx={{ mr: 6, mt: 1, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button variant="outlined" onClick={handleClose}>
              キャンセル
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={async () => await handlePostApi(order.po_do_id)}
            >
              登録
            </Button>
          </Box>
          <InformAlert
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            alertInfo={alertInfo}
          />
        </Box>
      </Modal>
    </>
  )
}

export default DeliveryEditModal
