export const activityNameColors = [
  /**
   * ganntチャートのactivity_nameに対応する色を指定する
   * 水色・黄緑・紫・黄色・赤
   */
  {
    id: '払出',
    name: '払出',
    color: '#00bfff',
  },
  {
    id: '外注組立',
    name: '外注組立',
    color: 'springgreen',
  },
  {
    id: '支給',
    name: '支給',
    color: '#00ff00',
  },
  {
    id: '部組',
    name: '部組',
    color: '#9932cc',
  },
  {
    id: '総組',
    name: '総組',
    color: '#ffff00',
  },
  {
    id: '出荷',
    name: '出荷',
    color: '#ff7f50',
  },
  {
    id: '納期',
    name: '納期',
    color: 'red',
  },
]
