export const colorOrder = [
  {
    order: 1,
    color: '#00bfff',
  },
  {
    order: 2,
    color: '#00ff00',
  },
  {
    order: 3,
    color: '#9932cc',
  },
  {
    order: 4,
    color: '#ffff00',
  },
  {
    order: 5,
    color: '#ff7f50',
  },
  {
    order: 6,
    color: 'deeppink',
  },
  {
    order: 7,
    color: 'moccasin',
  },
  {
    order: 8,
    color: 'teal',
  },
  {
    order: 9,
    color: 'coral',
  },
  {
    order: 10,
    color: 'lavender',
  },
  {
    order: 11,
    color: 'darkgreen',
  },
]
